import {CredencialesSAP, UrlSAP} from '../variables/variablesConection'

/*export async function GetApp(Url) {
    return new Promise((resolve) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "Usuario": CredencialesSAP,
            "Liga": Url
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(UrlSAP, requestOptions)
            .then(response => response.text())
            .then(result => {
                resolve(result);
            })
            .catch(error => console.log('error', error));
    });
}*/

export async function GetApp(Url,token) {
    return new Promise((resolve) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", token);
        const raw = JSON.stringify({
            "url": Url
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };
        fetch("https://consumos.talsa.com.co/api/talsa/v1/sap", requestOptions)
            .then(response => response.text())
            .then(result => {
                resolve(result);
            })
            .catch(error => console.log('error', error));
    });
}


