import React, { useState } from 'react';
import '../cssComponentes/SubCabeza.css';
import cabeza from '../recursos/subcabeza.jpg';
import cabezav2 from '../recursos/subcabezav2.jpg';
import { Container, Grid, Paper, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import logo from '../recursos/LOGOS SIN SLOGAN.png';
import { getAsesores } from '../consumos/asesores'
import { getClientes } from '../consumos/clientes'
import { getZona } from '../consumos/zonaTel'


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function SubCabeza({ data }) {

    const [nombre, setNombre] = React.useState(data[0].Asesor);
    const [correo, setCorreo] = React.useState(data[0].Correo);
    const [telefono, setTelefono] = React.useState(data[0].Telefono);
    const [zona,setZona] = React.useState(data[0].zona);
    const [zonaTel,setZonaTel] = React.useState("");

    getAsesores(nombre).then((result) => {
        if (!result) {

        } else {
            setNombre(result.nombre)
            setCorreo(result.correo)
            setTelefono(result.telefono)
        }
    })

    getClientes(data[0].Cliente).then((result) => {
        if (!result) {

        } else {
            setNombre(result.nombre)
            setCorreo(result.correo)
            setTelefono(result.telefono)
        }
    })

    getZona(data[0].zona).then((result)=>{
        if (!result) {
            setZonaTel(telefono)
        } else {
            setZonaTel(result.telefono)
        }
    })

    return (
        <Container style={{ position: 'relative', }}>

            <Grid container spacing={2} style={{ position: 'relative', textAlign: "justify" }}>
                
            <Grid item xs={12} md={12} style={{ margin: "20px", textAlign:"end" }}>
                    <Container >
                        <img
                            src={logo}
                            alt="Descripción de la imagen"
                            style={{ width: '15%', height: 'auto' }}
                        />
                    </Container>
                </Grid>
                <Grid item xs={12} md={6} style={{ margin: "20px" }}>
                    <Typography variant='h6' style={{ fontSize: "1em" }} id="">Cotización:  {data[0].Cotizacion} </Typography>
                   {/*<Typography variant='h6' style={{ fontSize: "1em" }} id="">Fecha de creación : {data[0].Fecha.replace(/[T].*?[Z]/, '')}</Typography>*/} 
                   <Typography variant='h6' style={{ fontSize: "1em" }} id="">Fecha de creación : {data[0].Fecha}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography style={{ fontSize: "1em", margin: "20px" }} variant='h4' id="" fontWeight="bold" > {data[0].Cliente} </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='h6' style={{ fontSize: "1em", margin: "20px" }}>
                        Apreciado cliente, {data[0].contacto} <br /><br />

                        Soy <b>{nombre}</b>, del área comercial de Talsa y para mi es una gran satisfacción tener
                        la oportunidad de ofrecerle nuestros productos y servicios. De esta manera deseamos como
                        compañía ser parte del crecimiento de su empresa.<br /><br />

                        Atendiendo a su amable solicitud, anexo la información solicitada de acuerdo a sus requerimientos;
                        y lo invitamos a visitar nuestra página web <a
                            href={`https://www.citalsa.com/`}
                            target="_blank"
                            rel="noopener noreferrer"
                        > <b>www.talsa.com.co</b></a>, donde podrá encontrar todas las
                        soluciones tecnológicas que tenemos para ofrecer a su negocio.<br /><br />

                        Quedo a su disposición, para cualquier duda o información adicional que requiera, puede contactarme
                        vía Whatsapp y/o celular al siguiente número <a
                            href={`https://api.whatsapp.com/send?phone=${telefono.replace(/\s/g, '')}&text=Hola, recibí la propuesta comercial ${data[0].Cotizacion} y quisiera resolver algunas dudas con la negociación`}
                            target="_blank"
                            rel="noopener noreferrer"
                        > <b> {telefono} </b> </a> vía correo electrónico <a href={`mailto:${correo}`}>{correo}</a> y con todo el gusto le atenderé su solicitud.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='h5' id="Firma" style={{ fontSize: "1em", margin: "20px" }}>
                        <b>Asesor comercial:</b> {nombre} <br />
                        <b>Correo electrónico:</b> {correo} <br />
                        <b>Celular:</b>
                        <a
                            href={`https://api.whatsapp.com/send?phone=${data[0].Telefono.replace(/\s/g, '')}&text=Hola, recibí la propuesta comercial ${data[0].Cotizacion} y quisiera resolver algunas dudas con la negociación`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >  {telefono}  </a> <br />
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant='h5' id="Firma" style={{ fontSize: "1em", margin: "20px" }}>
                        <p>También puede contactar al coordinador comercial de zona {zona} al número :<a
                            href={`https://api.whatsapp.com/send?phone=${data[0].Telefono.replace(/\s/g, '')}&text=Hola, recibí la propuesta comercial ${data[0].Cotizacion} y quisiera resolver algunas dudas con la negociación`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >  {zonaTel}  </a></p> 
                         <br />
                    </Typography>
                </Grid>


                <Grid item xs={12}>
                    <Container style={{ width: "100%"}}>
                        <img
                            src={cabezav2}
                            alt="Descripción de la imagen"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Container>

                </Grid>

            </Grid>
        </Container>
    );


}

